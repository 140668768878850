import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './About.css';
import personalPhoto from '../assets/personal_image.jpg';

const About: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page-container">
      <div className="about-page-image-section">
        <img src={personalPhoto} alt="Your Name" />
      </div>
      <div className="about-page-text-section">
        <h1 className="about-page-header">Mahmoud Mraisi</h1>
        <p className="about-page-subheader">Full Stack & Mobile Developer</p>
        <p className="about-page-description">
          As an experienced software developer with expertise in Full-Stack
          Development, particularly in MERN (MongoDB, Express.js, React,
          Node.JS) stack, I am well-versed in web development and data
          integration. In my previous role, I developed and integrated over 100
          APIs using NodeJS, seamlessly integrating CRM and Sales Systems with
          SAPB1, resulting in streamlined logistics and improved efficiency for
          clients. I also enhanced payment processes for academic organizations,
          integrating their billing systems with SAPB1. Additionally, I built
          and managed integrations for various eCommerce systems, including
          Shopify and Magento, successfully leading tech projects end-to-end.
        </p>
        <NavLink
          className="about-page-button"
          to={
            'https://docs.google.com/document/d/1Al19-He6Hxs0gU0Xd_ye5Uu8kmzELEe4-5qbaYTgkqs/edit?usp=sharing'
          }
          target="_blank"
        >
          Resume
        </NavLink>
      </div>
    </div>
  );
};

export default About;
