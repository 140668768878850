import React from 'react';
import './App.css';
import Hero from './components/Hero';
import About from './components/About';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Skills from './components/Skills';
import Work from './components/Work';
import Contact from './components/Contact';
import Reviews from './components/Reviews';

function App() {
  return (
    <Layout>
      <style>
        {`@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@500&display=swap');`}
      </style>
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/work" element={<Work />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

export default App;
